<template>
  <div class="payrecord">
    <top-bar title="缴费明细" fixed></top-bar>
    <div>
      <div class="empty-record" v-if="list.length == 0">暂无记录</div>
      <div v-else>
        <van-list
            class="list-wrapper"
            v-model="isLoading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            :immediate-check="false"
        >
          <section class="pay-info" v-for="(item, index) in list" :key="index">
            <div class="pay01"><span>缴费金额：￥{{ item.charge }}</span><!-- <span>缴费方式:支付宝</span> --></div>
            <div class="pay02">缴费方式：{{ item.channel }}</div>
            <div class="pay02">缴费方法：{{ item.method }}</div>
            <div class="pay02">缴费时间：{{ new Date(item.chargeTime).getTime() | formatDate('yyyy年MM月dd日 hh:mm') }}</div>
          </section>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar'
import ajax from '@/api'
import {formatDate} from '@/utils/tools'

export default {
  name: 'PayRecord',
  inject: ['reload'],
  components: {TopBar},
  data() {
    return {
      isLoading: false,
      finished: false,
      pageNo: 0,
      pageSize: 10,
      list: [],
      bindId: this.$route.query.bindId
    }
  },
  watch: {},
  created() {
    this.loadData(this.pageNo)
  },
  filters: {
    formatDate,
  },
  methods: {
    onLoad() {
      this.loadData(this.pageNo + 1)
    },
    loadData() {
      ajax.getIncomeRecord({
        bindId: this.bindId,
      }).then(res => {
        this.list = this.list.concat(res.data)
        this.isLoading = false
        this.pageNo = res.current
        this.finished = res.pages === res.current
      })
    }
  }
}

</script>
<style lang="scss" scoped>
@import './style.scss';

.empty-record {
  height: 100px;
  line-height: 100px;
  text-align: center;
  color: #999;
  font-size: 14px;
}

.list-wrapper {
}
</style>
